import React from "react"

const about = {
  title: (
    <>
      the <span>ivy</span> coworking
    </>
  ),
  desc:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita cupiditate ullam, aut rem inventore nihil assumenda nostrum dolorem pariatur omnis.",
}

export default about
